<template>
	<div class="comment">
		<div class="head">
			<div>商品详情</div>
			<div>收货人</div>
			<div>等级</div>
			<div>操作</div>
		</div>
		<div class="content">
			<div class="contlist" v-for="(item,index) in list.records" :key='index'>
				<div class="contli_a">
					<div>订单号：{{item.orderNo}}</div>
					<div style="color: #818181;margin-left: 14px;">{{item.createTime}}</div>
				</div>
				<div class="contli_b">
					<div class="contli_left">
						<img :src="item.goodsImage" />
						<div>
							<div class="contli_left_a">{{item.goodsName}}</div>
							<div style="color: #818181;">{{item.specs}}</div>
						</div>
						<div class="shouhname">{{item.memberName}}</div>
						<div v-if="item.grade=='GOOD'">好评</div>
						<div v-else-if="item.grade=='MODERATE'">中评</div>
						<div v-else>差评</div>
					</div>
					<div class="contli_right">
						<span style="cursor: pointer;" @click="orderxx(item.orderNo,1)">订单详情</span>
						<span> | </span>
						<span style="cursor: pointer;" @click="orderxx(item.orderNo,2)">评价详情</span>
					</div>
				</div>
			</div>
		</div>
		<div class="pagings">
			<el-pagination background layout="prev, pager, next" 
			:page-size='parsm.pageSize' 
			@current-change="CurrentChange" 
			:total="list.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { getcommt } from "@/request/xie";
	export default{
		data(){
			return{
				list:[],
				parsm:{
					pageNumber:1,
					pageSize:5
				}
			}
		},
		mounted() {
			this.getlist()
		},
		methods:{
			//获取评价列表
			async getlist(){
				let res=await getcommt(this.parsm)
				this.list=res.result
				console.log(res)
			},
			//页数改变时
			CurrentChange(page){
				this.parsm.pageNumber = page;
				this.getlist();
			},
			//订单详情
			orderxx(id,ind){
				if(ind==1){
					this.$router.push({
						path:'./myoderdetail',
						query:{orderSn:id,step:5}
					})
				}else{
					this.$router.push({
						path:'./myoderdetail',
						query:{orderSn:id,step:5,isorder:1}
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.comment{
		background-color: #fff;
		padding: 25px;
		box-sizing: border-box;
		.head{
			width: 100%;
			height: 40px;
			background-color: #F3F3F3;
			display: flex;
			flex-flow: row;
			justify-content: space-between;
			align-items: center;
			color: #535353;
			font-size: 14px;
			font-weight: bold;
			div:nth-child(1){
				width: 440px;
				text-align: center;
			}
			div:nth-child(4){
				width: 137px;
				margin-right: 30px;
				text-align: center;
			}
		}
		.content{
			margin-top: 14px;
			background-color: #fff;
			.contlist{
				border: 1px solid #F0F0F0;
				margin-bottom: 16px;
				.contli_a{
					padding-left: 23px;
					height: 40px;
					background-color: #F3F3F3;
					display: flex;
					line-height: 40px;
					color: #535353;
					font-size: 14px;
				}
				.contli_b{
					padding: 28px 45px 28px 28px;
					box-sizing: border-box;
					display: flex;
					flex-flow: row;
					justify-content: space-between;
					align-items: center;
					.contli_left{
						font-size: 14px;
						display: flex;
						align-items: center;
						.contli_left_a{
							width: 270px;
							margin-bottom: 5px;
							margin-right: 20px;
							overflow: hidden;
							text-overflow: ellipsis;
							display:-webkit-box; 
							-webkit-box-orient:vertical;
							-webkit-line-clamp:2;
						}
						img{
							width: 143px;
							height: 80px;
							margin-right: 18px;
							border: 1px solid #DCDCDC;
						}
						.shouhname{
							width: 200px;
							margin-right: 20px;
							text-align: center;
							overflow: hidden;
							text-overflow: ellipsis;
							display:-webkit-box; 
							-webkit-box-orient:vertical;
							-webkit-line-clamp:2;
						}
					}
					.contli_right{
						color: #00A3E0;
						font-size: 14px;
					}
				}
				
			}
		}
		.pagings{
			display: flex;
			flex-flow: row;
			justify-content: flex-end;
			margin-top: 50px;
		}
	}
</style>
